import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { updateBookNotes } from "../../api/BookApi";
import { uploadFile } from "../../api/UploadApi";

function BookNotesDialog({ data, onUpdate, onCancel }) {
  const [notes, setNotes] = useState({
      fullNotes: null,
    }),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleFileChange = (e) => {
      const key = e.target.name,
        value = e.target.files[0];

      updateNote(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      uploadFile("notes", notes.fullNotes).then((fullPath) => {
        updateBookNotes({
          id: data.id,
          fullNotesPath: fullPath,
        }).then((book) => {
          onUpdate && onUpdate(book);
        });
      });
    },
    updateNote = (key, value) => {
      let change = {};

      change[key] = value;
      setNotes({ ...notes, ...change });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Upload Book Notes</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              inputProps={{ accept: "application/pdf" }}
              label="Full Notes"
              name="fullNotes"
              onChange={handleFileChange}
              required
              sx={{ mt: 1 }}
              type="file"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default BookNotesDialog;
